import React, { useState } from "react";
import { useParams } from "react-router";
import Footer from "../../Main/Components/Footer/Footer";
import Header from "../../Main/Components/Header/Header";
import InfoBlock from "./InfoBlock";
import ContactForm from "../../../CommonComponents/ContactForm/ContactForm";
import PopupForm from "../../../CommonComponents/PopupForm/PopupForm";
import { CoursesArray } from "../../../Arrays/CoursesArray";

const CoursePage = () => {
  const [isHidden, setHidden] = useState(true);

  const handleClick = () => {
    setHidden(!isHidden);
  };

  const format = {
    title: 'Онлайн',
    description: 'Наразі більшість наших лекцій проходить в онлайн форматі. Це зроблено передусім з міркувань безпеки і для забезпечення більш гнучкого графіку навчання для наших слухачів',
    app: ['skype', 'Zoom', 'viber']
  }

  

  const id = useParams();

  const course = CoursesArray.filter((el) => el.id == id.id);
  const {
    name,
    lectionTime,
    length,
    price,
    program,
    type,
    studentsNumber,
    language,
    description,
  } = course[0];

  console.log(program)

  return (
    <div className="course-page__container">
      {!isHidden && (
        <div className="popup-anchor__container" onClick={() => handleClick()}>
          <PopupForm
            child={<ContactForm closePopup={handleClick} />}
          ></PopupForm>
        </div>
      )}
      <Header></Header>
      <div className="course-page__main">
        <div className="course-page__info">
          <div>{type}</div>
          <div className="course-page__info_container">
            <div className="course-page__info_header">
              <h5 className="course-page__info_text">{name}</h5>
              <div className="course-page__info_blocks">
                <div className="course-page__info_block">
                  <span>Тривалість: {length}</span>
                  <span>{lectionTime}</span>
                </div>
                <div className="course-page__info_block">
                  Кількість слухачів: {studentsNumber}{" "}
                </div>
                <div className="course-page__info_block">
                  Вартість: {price} грн.
                </div>
                <div className="course-page__info_block">Мова: {language}</div>
              </div>
              <div
                className="course-page__button"
                onClick={() => handleClick()}
              >
                Записатись
              </div>
            </div>
            <div className="course-page__info_image"></div>
          </div>
        </div>
        <div className="course-page__more-info">
          <InfoBlock content={description} header={"Опис"}></InfoBlock>
          <InfoBlock content={program} header={"Програма курсу"}></InfoBlock>
          <InfoBlock format = {format} content="" header={"Формат"}></InfoBlock>
        </div>
      </div>
      <button
        onClick={() => handleClick()}
        style={{marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '20px',
        marginBottom: '30px',
      }}
        className="course-page__button"
      >
        Записатись
      </button>
      <Footer></Footer>
    </div>
  );
};

export default CoursePage;
