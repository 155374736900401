import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='footer'>
            {/* <div className='footer__links'>
            <div className='footer__links_container'>
            Ми в соціальних мережах
            <Link to ='https://www.facebook.com/people/%D0%A1%D0%B5%D0%BC%D1%96%D0%BD%D0%B0%D1%80-%D1%82%D1%80%D0%B5%D0%BD%D1%96%D0%BD%D0%B3-%D1%88%D0%BA%D0%BE%D0%BB%D0%B0-WTS/100064810217621/'className='footer__links_link'/>
            <Link to ='https://instagram.com/wts_school?igshid=MzRlODBiNWFlZA==' className='footer__links_link'></Link>
            </div>
            <div className='footer__links_contact-us'>Залишились питання? Заповніть форму або 
            напишіть нам 
            <div className='footer__links_telegram'></div> wts_school</div>
        </div> */}
        <div className='footer__header'>
        <div className='footer__links_container'>
            {/* Ми в соціальних мережах */}
            <Link to ='https://www.facebook.com/people/%D0%A1%D0%B5%D0%BC%D1%96%D0%BD%D0%B0%D1%80-%D1%82%D1%80%D0%B5%D0%BD%D1%96%D0%BD%D0%B3-%D1%88%D0%BA%D0%BE%D0%BB%D0%B0-WTS/100064810217621/'className='footer__links_link'/>
            <Link to ='https://instagram.com/wts_school?igshid=MzRlODBiNWFlZA==' className='footer__links_link'></Link>
            </div>
                <div className='footer__header_adress'>М.Київ, Регенераторна 4</div>
                {/* <div className='footer__header_logo'></div> */}
                <div className='footer__images'>
                <div className='footer__image'></div>
                <div className='footer__image'></div>
                </div>
                <div className='footer__header_phone'>+38 (098) 062-58-68</div>
                <div className='footer__header_email'>info@w-ts.com.ua</div>
                </div>
        </div>
    );
};

export default Footer;