import React from "react";
import { Link } from "react-router-dom";
import { TestsArray } from "../../../../Arrays/TestsArray";

const Tests = () => {
  return (
    <div className="tests-main">
      <div className="tests-main__header">Онлайн тестування</div>
      <div className="tests-main__container">
        {TestsArray.map((el) => (
          <div className="tests-main__block">
            <h2 className="tests-main__block_header">{el.testName}</h2>
            <div className="tests-main__block_description">
              {el.testDescription}
            </div>
            <Link to={`/test/${el.testId}`} className="tests-main__button">Більше інформації</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tests;
