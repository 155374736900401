import React from "react";
import Footer from "../../../Main/Components/Footer/Footer";
import Header from "../../../Main/Components/Header/Header";

const Payment = () => {
  return (
    <div className="payment">
      <Header></Header>
      <div className="payment__container">
        <div className="payment__text">
          <p></p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Payment;
