import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { mailContext } from "../../index";
import { CoursesArray } from "../../Arrays/CoursesArray";

const ContactForm = ({ closePopup }) => {
  const id = useParams();
  const courseName = CoursesArray.filter((el) => {
    if (el.id == id.id) return el;
  })[0].name;

  console.log(courseName);
  const [isSent, checkSent] = useState(false);
  const [formData, setFormData] = useState({
    courseName,
    name: "",
    email: "",
    message: " ",
    phone: "+38",
  });

  const { mailStore } = useContext(mailContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const response = mailStore.sendMail(formData);
    checkSent(!isSent);
    setTimeout(() => {
      checkSent();
      closePopup();
    }, 1000);
  };

  return (
    <div className="contact-form">
      <header className="contact-form__header">{courseName}</header>
      {!isSent ? (
        <form className="contact-form__container" onSubmit={handleSubmit}>
          <div className="contact-form__field name">
            <label className="contact-form__label" htmlFor="name">
              ПІБ:
            </label>
            <input
              className="contact-form__input"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="contact-form__field phone">
            <label className="contact-form__label" htmlFor="phone">
              {" "}
              Номер телефону:
            </label>
            <input
              className="contact-form__input"
              type="text"
              id="phone"
              name="phone"
              placeholder="+38(000) 000-00-00"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="contact-form__field email">
            <label className="contact-form__label" htmlFor="email">
              Email:
            </label>
            <input
              className="contact-form__input"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="contact-form__field message">
            <label className="contact-form__label" htmlFor="message">
              Примітка:
            </label>
            <textarea
              className="contact-form__input"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
          </div>
          <button className="contact-form__button" type="submit">
            Записатись
          </button>
        </form>
      ) : (
        <div className="contact-form__container-second">
          <div className="contact-form__container-second_text">
            Дякуємо за реєстрацію!
          </div>
          <div className="contact-form__container-second_image"></div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
