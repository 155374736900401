import React from "react";

const Example = () => {
  return (
    <div className="example">
      <h1 className="example__header">Наші сертифікати</h1>
      <div className="example__image_container">
        <div className="example__image"></div>
        <div className="example__image"></div>
      </div>
    </div>
  );
};

export default Example;
