import React from "react";
import Flickity from "react-flickity-component";
import Card from "./Card"
import Course from "../../../Courses/Components/Course/Course";
import { CoursesArray } from "../../../../Arrays/CoursesArray";


const Carousel = () => {

    return (
    <div className="carousel">
    <div className="carousel__container">
    <h3 className="carousel__header">Окрім лекцій по HACCP ми пропонуємо широкий спектр курсів і майстер-классів для підвищення кваліфікації працівників сфери харчової промисловості</h3>
    <Flickity 
    options={{
        wrapAround: true,
        initialIndex: 2
    }}>
    {CoursesArray.map( el => { return <Course key = {el.id} courseObj = {el}></Course>})}

    </Flickity>
    </div>
    {/* <div className="carousel__container">
    <h2 className="carousel__header">Кулінарні майстер-класи</h2>
    <Flickity 
    options={{
        wrapAround: true,
        initialIndex: 1
    }}>
    {CoursesArray.map( el => { if (el.type != 'Лекція') return <Course key = {el.id} courseObj = {el}></Course>})}


    </Flickity>
    </div> */}
    </div>
    );
};

export default Carousel;