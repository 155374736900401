import React from "react";
import Header from "../Header/Header";
import About from "../About/About";
import Information from "../Information/Information";
import Carousel from "../Carousel/Carousel";
import Tests from "../Tests-main/Tests";
import Footer from "../Footer/Footer";
import Levels from "../Levels/Levels";
import Example from "../Sertificate-example/Example";

const Main = () => {
  return (
    <div className="main">
      <Header></Header>
      <About></About>
      <Levels></Levels>
      <Information></Information>
      <Example></Example>
      <Carousel></Carousel>
      <Tests></Tests>
      <Footer></Footer>
    </div>
  );
};

export default Main;
